<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : 'pc'}`">
    <div class="content">
      <img :src="$ossRes('/activity/feilong_buy_succ.png')" alt="" srcset="" />
      <p class="tips1">你已成功购买飞龙车模！</p>
      <p class="tips2">请等待客服与您联系</p>
      <div
        class="create-action cursor-pointer"
        @click.stop.prevent="csOpen"
        v-if="!$utils.checkWxOrMini(1)"
      >
        <span>联系客服</span>
      </div>
      <div
        style="width: 272px; height: 80px; margin: auto; position: relative"
        v-if="wx_cs_show"
      >
        <!-- <img :src="$ossRes('/activity/feilong_kefu.png')" alt="" srcset="" /> -->
        <!-- <WxOpenLaunchWeapp :path="`subpackage_login_cy/cs/cs`">
          <div :style="weappStyle.div">
            <span :style="{
              ...weappStyle.span,
              position: 'absolute',
              top: '-20px',
            }">客服</span>
          </div>
        </WxOpenLaunchWeapp> -->

        <WxOpenLaunchWeapp :path="`subpackage_login_cy/cs/cs`">
          <div
            style="
              width: 272px;
              height: 40px;
              line-height: 40px;
              background: #4d12da;
              border-radius: 205px 205px 205px 205px;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              margin: auto;
              z-index: 999;
            "
          >
            <span
              style="
                font-size: 18px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
              "
              >联系客服</span
            >
          </div>
        </WxOpenLaunchWeapp>
      </div>
    </div>
    <action-confirm :show="csShow" @close="csClose" :needAction="false">
      <div class="action-confirm-tips">
        <img :src="csQrcode.url" />
        <span>长按识别二维码联系客服</span>
      </div>
    </action-confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesData } from "@/router/index";
import { wxShare } from "@/assets/js/wechatAuth.js";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";

export default {
  components: { ActionConfirm, WxOpenLaunchWeapp },
  data() {
    return {
      csShow: false,
      payShow: false,
      user_name: "",
      phone: "",
      address: "",
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "14.4px",
          fontFamily: "Source Han Sans CN-Light, Source Han Sans CN",
          fontWeight: "300",
          color: "#ffffff",
        },
      },
      wx_cs_show: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["csQrcode"]),
  },
  created() {
    wxShare({});
    if (!this.userInfo) {
      // 未登录
      this.$router.replace({
        name: routesData.index.name,
      });
    }
    this.$store.dispatch("saveCsQrcode", {
      notActivityId: true,
    });
    let that = this;
    if (this.$utils.checkWxOrMini(1)) {
      setInterval(() => {
        that.wx_cs_show = true;
      }, 800);
    }
  },
  methods: {
    csOpen() {
      if (sessionStorage.getItem("isWxMini")) {
        parent.wx.miniProgram.redirectTo({
          url: `/subpackage_login_cy/cs/cs`,
        });
      } else {
        this.csShow = true;
      }
    },
    csClose() {
      this.csShow = false;
    },
    // back() {
    //   this.$router.back();
    // },
  },
};
</script>

<style lang="scss">
.index {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  // overflow: hidden;
  background: #fafafc;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  // background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
  // background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .action-confirm-tips {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 1840px;
      height: 1840px;
      margin-top: 257px;
      margin-bottom: 155px;
    }

    span {
      font-size: 184px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #646464;

      &.tips {
        font-size: 28px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #999999;
        margin-top: 16px;
      }
    }
  }

  .content {
    text-align: center;

    img {
      width: 819px;
      margin-top: 1464px;
    }

    p {
      text-align: center;
    }

    .tips1 {
      font-size: 225px;
      font-family: Source Han Sans-Medium, Source Han Sans;
      font-weight: 500;
      color: #333333;
      margin-top: 255px;
    }

    .tips2 {
      font-size: 184px;
      font-family: Source Han Sans-Normal, Source Han Sans;
      font-weight: 350;
      color: #666666;
      margin-top: 154px;
    }
  }
}

.create-action {
  width: 2780px;
  height: 410px;
  line-height: 410px;
  background: linear-gradient(82deg, #5300c7 4%, #452cf7 45%, #21affd 99%);
  border-radius: 205px 205px 205px 205px;
  text-align: center;
  margin: 369px auto 0 auto;
  position: relative;
  z-index: 0;

  span {
    font-size: 184px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
}

@media (orientation: portrait) {
  .index {
    display: block;
    padding-bottom: 0;
  }

  .action-confirm-tips {
    img {
      width: 1840px;
      height: 1840px;
      margin-top: 257px;
      margin-bottom: 155px;
    }

    span {
      font-size: 184px;

      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}

@media (orientation: landscape) {
  .pc {
    .van-calendar__popup {
      width: 1000px !important;
      height: 1450px !important;
      left: 50% !important;
      bottom: 50% !important;
      margin-left: -500px !important;
      margin-bottom: -725px !important;
      border-radius: 60px !important;
    }
  }
}
</style>
